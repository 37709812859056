import { Routes } from '@angular/router';
import { loggedInGuard } from './core/guards/logged-in/logged-in.guard';
import { loggedOutGuard } from './core/guards/logged-out/logged-out.guard';
import { BaseComponent } from './views/base/base.component';
import { ForbiddenComponent } from './views/errors/forbidden/forbidden.component';
import { PageNotFoundComponent } from './views/errors/page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        title: 'Dashboard',
        loadComponent: () =>
          import('./views/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },
      {
        path: 'configuration',
        title: 'Configuration',
        loadComponent: () =>
          import('./views/configuration/configuration.component').then(
            (m) => m.ConfigurationComponent
          ),
      },
      {
        path: 'online-stores',
        children: [
          {
            path: 'categories',
            title: 'Categories',
            loadComponent: () =>
              import(
                './views/online-stores/categories/categories.component'
              ).then((m) => m.CategoriesComponent),
          },
          {
            path: 'products',
            children: [
              {
                path: 'all-products',
                title: 'Products',
                loadComponent: () =>
                  import(
                    './views/online-stores/products/all-products/all-products.component'
                  ).then((m) => m.AllProductsComponent),
              },
              {
                path: 'add-product',
                title: 'Products',
                loadComponent: () =>
                  import(
                    './views/online-stores/products/add-product/add-product.component'
                  ).then((m) => m.AddProductComponent),
              },
              { path: '**', redirectTo: 'all-products', pathMatch: 'full' },
            ],
          },
          {
            path: 'orders',
            children: [
              {
                path: '',
                title: 'All Orders',
                loadComponent: () =>
                  import(
                    './views/online-stores/orders/all-orders/all-orders.component'
                  ).then((m) => m.AllOrdersComponent),
              },
              {
                path: ':orderID',
                title: 'Order Details',
                loadComponent: () =>
                  import(
                    './views/online-stores/orders/order-details/order-details.component'
                  ).then((m) => m.OrderDetailsComponent),
              },
            ],
          },
        ],
      },

      {
        path: 'finance-management',
        children: [
          {
            path: 'commission',
            title: 'Commission',
            loadComponent: () =>
              import(
                './views/finance-management/commission/commission.component'
              ).then((m) => m.CommissionComponent),
          },
        ],
      },
      {
        path: 'services',
        children: [
          {
            path: 'categories',
            title: 'Categories',
            loadComponent: () =>
              import('./views/services/categories/categories.component').then(
                (m) => m.CategoriesComponent
              ),
          },
          {
            path: 'provider-services',
            title: 'Provider Services',
            loadComponent: () =>
              import(
                './views/services/provider-services/provider-services.component'
              ).then((m) => m.ProviderServicesComponent),
          },
          {
            path: 'appointments',
            children: [
              {
                path: '',
                title: 'All Appointments',
                loadComponent: () =>
                  import(
                    './views/services/appointments/all-appointments/all-appointments.component'
                  ).then((m) => m.AllAppointmentsComponent),
              },
              {
                path: ':appointmentID',
                title: 'Appointment Details',
                loadComponent: () =>
                  import(
                    './views/services/appointments/appointment-details/appointment-details.component'
                  ).then((m) => m.AppointmentDetailsComponent),
              },
            ],
          },
        ],
      },

      { path: 'dashboard', redirectTo: '' },
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: 'auth',
    canActivate: [loggedOutGuard],
    children: [
      {
        path: 'login',
        title: 'Login',

        loadComponent: () =>
          import('./views/auth/login/login.component').then(
            (m) => m.LoginComponent
          ),
      },
      {
        path: 'forget-password',
        title: 'Forget Password',
        loadComponent: () =>
          import('./views/auth/forget-password/forget-password.component').then(
            (m) => m.ForgetPasswordComponent
          ),
      },
      {
        path: 'reset-password',
        title: 'Reset Password',
        loadComponent: () =>
          import('./views/auth/reset-password/reset-password.component').then(
            (m) => m.ResetPasswordComponent
          ),
      },
      {
        path: 'login-verification',
        title: 'Login Verification',
        loadComponent: () =>
          import(
            './views/auth/login-verification/login-verification.component'
          ).then((m) => m.LoginVerificationComponent),
      },
      {
        path: 'forget-password-verification',
        title: 'Forget Password Verification',
        loadComponent: () =>
          import(
            './views/auth/forget-password-verification/forget-password-verification.component'
          ).then((m) => m.ForgetPasswordVerificationComponent),
      },
      {
        path: 'login-with-otp',
        title: 'Login With OTP',
        loadComponent: () =>
          import('./views/auth/login-with-otp/login-with-otp.component').then(
            (m) => m.LoginWithOtpComponent
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
    ],
  },
  {
    path: 'errors',
    children: [
      {
        path: '404',
        title: 'Page Not Found',
        component: PageNotFoundComponent,
      },
      {
        path: '401',
        title: 'Unauthorized',
        component: ForbiddenComponent,
      },
    ],
  },

  { path: '**', redirectTo: 'error/404', pathMatch: 'full' },
];
