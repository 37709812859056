<header class="header">
  <section class="menu-title-container">
    <figure (click)="this.openCloseSideMenu()">
      <img src="svgs/icons/menu.svg" alt="Menu Toggle" />
    </figure>
    <h1>{{ this.title.getTitle() }}</h1>
  </section>
  <section class="profile-container">
    <figure class="profile-picture">
      <img
        [src]="
          this.user?.profilePicture?.url ??
          'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'
        "
        alt="profile picture"
      />
    </figure>
    <h2>{{ this.user?.brandName }}</h2>
    <figure class="profile-dropdown">
      <img src="svgs/icons/down-arrow.svg" alt="Down Arrow" />
    </figure>
  </section>
</header>
