import { NavItemI } from '../../../../core/shared/interfaces/general/nav-item.interface';

export const SIDE_MENU_ITEMS: NavItemI[] = [
  {
    translationPath: 'sideMenu.dashboard',
    activeIcon: '/svgs/side-menu/active-dashboard.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-dashboard.svg',
    route: 'dashboard',
    id: 1,
    alt: 'Dashboard',
  },

  {
    translationPath: 'sideMenu.services',
    activeIcon: '/svgs/side-menu/active-services.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-services.svg',
    route: 'services',
    id: 2,
    alt: 'Services',
    subRoutes: [
      {
        translationPath: 'sideMenu.categories',
        activeIcon: '/svgs/side-menu/active-categories.svg',
        nonActiveIcon: '/svgs/side-menu/non-active-categories.svg',
        route: 'services/categories',
        id: 3,
        alt: 'Categories',
      },
      {
        translationPath: 'sideMenu.providerServices',
        activeIcon: '/svgs/side-menu/active-services.svg',
        nonActiveIcon: '/svgs/side-menu/non-active-services.svg',
        route: 'services/provider-services',
        id: 4,
        alt: 'Provider Services',
      },
      {
        translationPath: 'sideMenu.appointments',
        activeIcon: '/svgs/side-menu/active-appointments.svg',
        nonActiveIcon: '/svgs/side-menu/non-active-appointments.svg',
        route: 'services/appointments',
        id: 5,
        alt: 'Provider Services',
      },
    ],
  },
  {
    translationPath: 'sideMenu.onlineStore',
    activeIcon: '/svgs/side-menu/active-store.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-store.svg',
    route: 'online-store',
    id: 6,
    alt: 'Online-store',
    subRoutes: [
      {
        translationPath: 'sideMenu.categories',
        activeIcon: '/svgs/side-menu/active-categories.svg',
        nonActiveIcon: '/svgs/side-menu/non-active-categories.svg',
        route: 'online-stores/categories',
        id: 7,
        alt: 'Categories',
      },
      {
        translationPath: 'sideMenu.products',
        activeIcon: '/svgs/side-menu/active-products.svg',
        nonActiveIcon: '/svgs/side-menu/non-active-products.svg',
        route: 'online-stores/products',
        id: 8,
        alt: 'Products',
      },

      {
        translationPath: 'sideMenu.orders',
        activeIcon: '/svgs/side-menu/active-products.svg',
        nonActiveIcon: '/svgs/side-menu/non-active-products.svg',
        route: 'online-stores/orders',
        id: 9,
        alt: 'Orders',
      },
    ],
  },
  {
    translationPath: 'sideMenu.financeManagement',
    activeIcon: '/svgs/side-menu/active-finance-management.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-finance-management.svg',
    route: 'finance-management',
    id: 10,
    alt: 'Finance management',

    subRoutes: [
      {
        translationPath: 'sideMenu.commission',
        activeIcon: '/svgs/side-menu/active-categories.svg',
        nonActiveIcon: '/svgs/side-menu/non-active-categories.svg',
        route: 'finance-management/commission',
        id: 11,
        alt: 'commission',
      },
    ],
  },

  {
    translationPath: 'sideMenu.settings',
    activeIcon: '/svgs/side-menu/active-settings.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-settings.svg',
    route: 'settings',
    id: 12,
    alt: 'Settings',
  },
  {
    translationPath: 'sideMenu.support',
    activeIcon: '/svgs/side-menu/active-support.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-support.svg',
    route: 'support',
    id: 13,
    alt: 'Support',
  },

  {
    translationPath: 'sideMenu.configuration',
    activeIcon: '/svgs/side-menu/active-settings.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-settings.svg',
    route: 'configuration',
    id: 14,
    alt: 'Configuration',
  },
];
