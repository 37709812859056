import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { StorageService } from '../storage/storage.service';
import { UserI } from '../../shared/interfaces/entities/user.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedApisService {
  constructor(
    private readonly apiService: ApiService,
    private readonly storageService: StorageService
  ) {}

  findLoggedInUser() {
    return this.apiService.get<UserI>(
      `users/${
        JSON.parse(atob(this.storageService.accessToken?.split('.')[1]!)).sub
      }`
    );
  }
}
